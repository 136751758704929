<template>
  <div>
    <el-card>
      <div class="flex flex-align-center flex-wrap">
        <div class="flex flex-align-center margin-right-10 margin-bottom-10">
          <div class="keyLabel">关键字：</div>
          <el-input v-model.trim="keywords" style="width: 220px" placeholder="属性名称"></el-input>
        </div>
        <el-button type="primary" class="margin-bottom-10" @click="query">查询</el-button>
        <buttonPermissions datas="shopattributeAdd" class="margin-left-10">
          <el-button type="primary" class="margin-bottom-10" @click="toadd">添加</el-button>
        </buttonPermissions>
      </div>
      <div class="margin-top-10" v-loading="tableloading">
        <!-- <div v-if="tableloading">
          <el-table	:data="tablelist" v-if="!searchKey">
            <el-table-column	prop="AttributeName"	label="属性名称" key="tab3-1"></el-table-column>
            <el-table-column	prop="AddTime"	label="创建时间" key="tab3-5">	</el-table-column>
            <el-table-column	prop=""	label="操作" key="tab3-6">	</el-table-column>
          </el-table>
          <el-table	:data="tablelist2" v-else>
            <el-table-column	prop="AttributeName"	label="属性名称" key="tab3-1"></el-table-column>
            <el-table-column	prop="AddTime"	label="创建时间" key="tab3-5">	</el-table-column>
            <el-table-column	prop=""	label="操作" key="tab3-6">	</el-table-column>
          </el-table>
        </div> -->
        <div>
          <el-table	:data="tablelist"	style="width: 100%" row-key="Id" empty-text="查询不到相关属性" key="tab1"
            :row-class-name="rowClassName" :tree-props="{children: 'LowerList', hasChildren: true}" ref="table">
            <el-table-column	prop="AttributeName"	label="属性名称" key="tab1-1">
              <template slot-scope="scope">
                <div class="ellipsis">
                  {{scope.row.AttributeName}}
                </div>
              </template>
            </el-table-column>
            <el-table-column	prop="AddTime"	label="创建时间" key="tab1-5">	</el-table-column>
            <el-table-column	label="操作" width="200px" key="tab1-6">
              <template slot-scope="scope">
                <div>
                  <buttonPermissions datas="shopattributeAdd" class="margin-right-10">
                    <el-button type="text" @click="addnext(scope.row)" v-if="scope.row.level<4">添加下一级属性</el-button>
                  </buttonPermissions>
                  <buttonPermissions datas="shopattributeEdit" class="margin-right-10">
                    <el-button type="text" @click="toedit(scope.row)">编辑</el-button>
                  </buttonPermissions>
                  <buttonPermissions datas="shopattributeDel" v-if="scope.row.IsLowest">
                    <el-button type="text" style="color:#f56c6c" @click="todel(scope.row)">删除</el-button>
                  </buttonPermissions>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- <el-table	:data="tablelist2"	style="width: 100%" row-key="Id" empty-text="查询不到相关属性" v-else key="tab2"
            :tree-props="{children: 'LowerList',hasChildren: true}" :row-class-name="rowClassName" default-expand-all ref="table">
            <el-table-column	prop="AttributeName"	label="属性名称" key="tab2-1">
              <template slot-scope="scope">
                <div class="ellipsis">
                  {{scope.row.AttributeName}}
                </div>
              </template>
            </el-table-column>
            <el-table-column	prop="AddTime"	label="创建时间" key="tab2-5">	</el-table-column>
            <el-table-column	label="操作" width="200px" key="tab2-6">
              <template slot-scope="scope">
                <div>
                  <buttonPermissions datas="shopattributeAdd" class="margin-right-10">
                    <el-button type="text" @click="addnext(scope.row)" v-if="scope.row.level<4">添加下一级属性</el-button>
                  </buttonPermissions>
                  <buttonPermissions datas="shopattributeEdit" class="margin-right-10">
                    <el-button type="text" @click="toedit(scope.row)">编辑</el-button>
                  </buttonPermissions>
                  <buttonPermissions datas="shopattributeDel" v-if="scope.row.IsLowest">
                    <el-button type="text" style="color:#f56c6c" @click="todel(scope.row)">删除</el-button>
                  </buttonPermissions>
                </div>
              </template>
            </el-table-column>
          </el-table> -->
        </div>
        <div class="margin-top-10" style="text-align: right" v-if="total">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>

    <el-dialog :visible.sync="addShow" title="添加属性" custom-class="dialog-body-paddingTop-10" width="550px" v-loading="addLoading">
      <div style="max-height:500px;overflow-y:auto">
        <div class="flex flex-align-center">
          <div class="keyLabel start">一级属性名称：</div>
          <el-input v-model="attribute.FirstProductAttributeName" style="width:300px" placeholder="最多输入10个字" maxlength="10"></el-input>
        </div>
        <div class="flex flex-align-center margin-top-20" v-for="(v,i) in attribute.SecondProductAttributeNameList" :key="i">
          <div class="keyLabel start">二级属性名称：</div>
          <el-input v-model="v.value" style="width:300px" placeholder="最多输入10个字" maxlength="10"></el-input>
          <el-button type="text" style="color:#f56c6c" @click="delSecond(i)" class="margin-left-10"
          v-if="attribute.SecondProductAttributeNameList.length>1">删除</el-button>
        </div>
        <el-button type="text" class="margin-top-20" style="margin-left:110px"
        v-if="attribute.SecondProductAttributeNameList.length<50" @click="addSecond">+ 添加二级属性</el-button>
      </div>
      <div slot="footer">
        <el-button @click="addShow=false">取消</el-button>
        <el-button type="primary" @click="saveAdd">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="nextShow" :title="`添加【${rowmsg.AttributeName}】下级属性`"
     custom-class="dialog-body-paddingTop-10" width="550px" v-loading="nextloading">
      <div class="flex" style="max-height:500px;overflow-y:auto">
        <div class="keyLabel start" style="line-height:36px">属性名称：</div>
        <div>
          <div class="margin-bottom-20" v-for="(v,i) in attribute.SecondProductAttributeNameList" :key="i">
            <el-input v-model="v.value" style="width:300px" placeholder="最多输入10个字" maxlength="10"></el-input>
            <el-button type="text" style="color:#f56c6c" @click="delSecond(i)" class="margin-left-10"
            v-if="attribute.SecondProductAttributeNameList.length>1">删除</el-button>
          </div>
        <el-button type="text" v-if="attribute.SecondProductAttributeNameList.length<50" @click="addSecond">+ 添加属性</el-button>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="nextShow=false">取消</el-button>
        <el-button type="primary" @click="savenext">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="editShow" title="编辑属性" custom-class="dialog-body-paddingTop-10" width="550px" v-loading="editloading">
      <div style="max-height:500px;overflow-y:auto">
        <div class="flex flex-align-center">
          <div class="keyLabel start">属性名称：</div>
          <el-input v-model="rowmsg.AttributeName" style="width:300px" placeholder="最多输入10个字" maxlength="10"></el-input>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="editShow=false">取消</el-button>
        <el-button type="primary" @click="saveedit">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import buttonPermissions from "@/components/buttonPermissions";
import {
  productAttributeproductAttributeInit,
  productAttributeproductAttributeAdd,
  productAttributeproductAttributeChild,
  productAttributeproductAttributeAddChild,
  productAttributeproductAttributeEdit,
  productAttributeproductAttributeDelete,
  productAttributeproductAttributeInitNew
} from "@/api/TurnTomySelf"
export default {
  components: {
    buttonPermissions,
  },
  data() {
    return {
      keywords: "",
      tablelist: [],
      tablelist2: [],
      expanded:[],
      tableloading: false,
      total:0,
      currentPage: 1,
      pagesize: 20,
      addShow:false,
      addLoading:false,
      attribute:{
        FirstProductAttributeName:'',
        SecondProductAttributeNameList:[
          {value:''}
        ]
      },
      nextShow:false,
      nextloading:false,
      rowmsg:{},
      editShow:false,
      editloading:false,
      searchKey:false,
      first:false,
      expandlist:[]
    };
  },
  computed: {
  },
  mounted () {
    this.first = true
    this.gettablelist()
  },  
  methods: {
    rowClassName({row}){
      if(row.IsLowest&&row.level!=1){
        return 'undertone'//最低层级添加底色
      }else{
        return 'replace'//有下级的替换i标签的内容
      }
    },
    resetLazyTree() {
      this.$set(this.$refs.table.store.states, 'lazyTreeNodeMap', {})
    },
    async getchildrenlist(tree, treeNode, resolve){
      try{
        let res = await productAttributeproductAttributeChild({
          Id:tree.Id,
          Skip:0, 
					Take: 99999, 
        })
        if(res.IsSuccess){
          let result = res.Result.map(v=>{
            v.level = tree.level+1
            v.hasChildren = !v.IsLowest
            if(!v.LowerList||!v.LowerList.length){
              v.LowerList = []
            }
            return v
          })
          // console.log(result)
          resolve(result)
          // console.log(this.tablelist)
          this.tablelist = this.unfolddata(this.tablelist,tree.Id,result)
        }
      }finally{
        //
      }
    },
    unfolddata(list,Id,data){
      return list.map(v=>{
        if(v.Id==Id){
          v.LowerList = data
          v.hasChildren = true
          return v
        }
        if(v.LowerList&&v.LowerList.length){
          v.LowerList = this.refreshdata(v.LowerList,Id)
        }
        return v
      })
    },
    async todel(row){
      this.$confirm(`删除成功后，不可恢复，是否确认删除属性【${row.AttributeName}】？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try{
          // this.tableloading = true
          let res = await productAttributeproductAttributeDelete({
            Id:row.Id
          })
          if(res.IsSuccess){
            this.$message.success('删除成功')
            this.resetLazyTree()
            // if(!this.searchKey){
              this.tablelist = this.refreshdata(this.tablelist,row.Id)
            // }else{
            //   this.tablelist2 = this.refreshdata(this.tablelist2,row.Id)
            // }
            // console.log(this.tablelist)
            // this.gettablelist()
          }
        }finally{
          // this.tableloading = false
        }
      })
    },
    refreshdata(list,Id){
      return list.filter(v=>{
        if(v.Id==Id){
          // console.log(v)
          return false
        }else{
          if(v.LowerList&&v.LowerList.length){
            v.LowerList = this.refreshdata(v.LowerList,Id)
          }
          return v
        }
      }).map(v=>{
        if(!v.LowerList||!v.LowerList.length){
          v.IsLowest = true
        }
        return v
      })
    },
    async saveedit(){
      try{
        this.editloading = true
        let res = await productAttributeproductAttributeEdit({
          Id:this.rowmsg.Id,
          ProductAttributeName:this.rowmsg.AttributeName
        })
        if(res.IsSuccess){
          this.$message.success('保存成功')
          this.editShow = false
          this.gettablelist()
        }
      }finally{
        this.editloading = false
      }
    },
    toedit(row){
      this.rowmsg = JSON.parse(JSON.stringify(row))
      this.editShow = true
    },
    async savenext(){
      for(let item of this.attribute.SecondProductAttributeNameList){
        if(!item.value){
          this.$message.error('请输入属性名称')
          return
        }
      }
      try{
        this.nextloading = true
        let res = await productAttributeproductAttributeAddChild({
          Id:this.rowmsg.Id,
          ChildProductAttributeNameList:this.attribute.SecondProductAttributeNameList.map(v=>v.value)
        })
        if(res.IsSuccess){
          this.$message.success('保存成功')
          this.nextShow = false
          this.resetLazyTree()
          this.tablelist = this.addnextPush(this.tablelist,res.Result)
          // console.log(this.tablelist)
          // this.gettablelist()
        }
      }finally{
        this.nextloading = false
      }
    },
    addnextPush(list,res){
      return list.map(v=>{
        if(v.Id==this.rowmsg.Id){
          v.IsLowest = false
          if(!v.LowerList||!v.LowerList.length){
            v.LowerList = []
          }
          v.LowerList = [...res.map(x=>{
            return {
              AttributeName:x.ProductAttributeName,
              Id:x.ProductAttributeId,
              IsLowest:true,
              level:v.level+1,
              LowerList:[],
              AddTime:v.AddTime
            }
          }),...v.LowerList]
        }else{
          if(v.LowerList&&v.LowerList.length){
            v.LowerList = this.addnextPush(v.LowerList,res)
          }
        }
        return v
      })
    },
    addnext(row){
      this.rowmsg = row
      this.attribute = {
        FirstProductAttributeName:'',
        SecondProductAttributeNameList:[
          {value:''}
        ]
      }
      this.nextShow = true
    },
    async saveAdd(){
      if(!this.attribute.FirstProductAttributeName){
        this.$message.error('请输入属性名称')
        return
      }
      for(let item of this.attribute.SecondProductAttributeNameList){
        if(!item.value){
          this.$message.error('请输入属性名称')
          return
        }
      }
      try{
        this.addLoading = true
        let res = await productAttributeproductAttributeAdd({
          ...this.attribute,
          SecondProductAttributeNameList:this.attribute.SecondProductAttributeNameList.map(v=>v.value)
        })
        if(res.IsSuccess){
          this.$message.success('保存成功')
          this.resetLazyTree()
          this.addShow = false
          this.gettablelist()
          // this.tablelist = [{
          //   AttributeName:this.attribute.FirstProductAttributeName,
          //   Id:res.Result.FirstProductAttributeId,
          //   IsLowest:false,
          //   level:1,
          //   AddTime:res.Result.AddTime,
          //   LowerList:res.Result.LowProductAttributeList.map(v=>{
          //     return {
          //       AttributeName:v.ProductAttributeName,
          //       Id:v.ProductAttributeId,
          //       IsLowest:true,
          //       level:2,
          //       LowerList:[],
          //       AddTime:v.AddTime
          //     }
          //   })
          // },...this.tablelist]
        }
      }finally{
        this.addLoading = false
      }
    },
    delSecond(i){
      this.attribute.SecondProductAttributeNameList.splice(i,1)
    },
    addSecond(){
      this.attribute.SecondProductAttributeNameList.push({
        value:''
      })
    },
    toadd(){
      this.attribute = {
        FirstProductAttributeName:'',
        SecondProductAttributeNameList:[
          {value:''}
        ]
      }
      this.addShow = true
    },
    handleSizeChange(e) {
      this.currentPage = 1;
      this.pagesize = e;
      this.gettablelist();
    },
    handleCurrentChange(e) {
      this.currentPage = e;
      this.gettablelist();
    },
    async gettablelist() {
      try {
        this.tableloading = true;
        let data = {
          Keywords:this.keywords,
          Skip: (this.currentPage - 1) * this.pagesize,
          Take: this.pagesize,
        };
        let res = await productAttributeproductAttributeInitNew(data);
        if (res.IsSuccess) {
          let tablelist = res.Result.Results.map(v=>{
            v.level = 1
            v.hasChildren = !v.IsLowest
            if(!v.LowerList||!v.LowerList.length){
              v.LowerList = []
            }
            return v
          })
          // if(this.searchKey){
          //   this.tablelist2 = this.getlevel(tablelist,0)
          //   this.tablelist = []
          // }else{
            this.tablelist = this.getlevel(tablelist,0)
          //   this.tablelist2 = []
          // }
          this.total = res.Result.Total
        }
      } finally {
        this.first = false
        this.tableloading = false;
      }
    },
    getlevel(list,level){
      return list.map(v=>{
        v.level = level+1
        if(v.LowerList&&v.LowerList.length){
          v.LowerList = this.getlevel(v.LowerList,v.level)
        }
        return v
      })
    },
    query(){
      this.currentPage = 1
      this.gettablelist()
      this.searchKey = Boolean(String(this.keywords))
      this.first = true
    }
  },
};
</script>

<style lang="less" scoped>
::v-deep .undertone.el-table__row{
  .cell{
    display: flex;
    align-items: center;
    .el-table__expand-icon{
      margin-top: 4px;
    }
  }
	background: #FBFBFBFF;
  .el-table__expand-icon{
			pointer-events: none;
		i{
			display: none;
			content:'' !important;
			width: 14px;
			height: 14px;
		}
		.el-icon-loading{
			animation:none;
		}
	}
	.el-table__expand-icon--expanded{
		  transform:none;
			pointer-events: none;
		i{
			display: none;
			content:'' !important;
			width: 14px;
			height: 14px;
		}
	}
}
::v-deep .replace{
  .cell{
    display: flex;
    align-items: center;
    .el-table__expand-icon{
      margin-top: 4px;
    }
  }
	.el-table__expand-icon{
		i{
			display: inline;
			content:url('http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/groupList-add.png') !important;
			width: 14px;
			height: 14px;
		}
		.el-icon-loading{
		  animation:none
		}
	}
	.el-table__expand-icon--expanded{
		transform:none !important;
		i{
			display: inline;
			content:url('http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/groupList-subtract.png') !important;
			width: 14px;
			height: 14px;
		}
	}
}
</style>
